import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>KedarTravels - Travel Guide of Uttrakhand</title>
                <meta name="description" content="KedarTravels - Travel Guide of Uttarakhand" />
                <meta name="og:title" property="og:title" content="KedarTravels - Travel Guide of Uttarakhand"></meta>
                <meta name="twitter:card" content="KedarTravels - Travel Guide of Uttarakhand"></meta>
                <link rel="canonical" href="https://www.kedartravels.com/"></link>
            </Helmet>
        </div>
    )
}

export default SEO
